.btn_req_loan {
  background-color: #102464;
  color: white;
  font-size: 12px;
  /* margin-top: 15px; */
  margin-right: 30px;
  font-weight: 600;
  border-radius: 7px !important;
}
.paper {
  padding-right: 10px;
}
.head_dash {
  margin-top: 25px;
  margin-bottom: 30px;
}
.headimg_team {
  font-weight: 700;
  font-size: 24px;
  padding-left: 20px;
}
.welcome_text {
  color: #767474;
  font-size: 16px;
  padding-left: 10px;
}
.loan_status {
  background: #f8f7ff 0% 0% no-repeat padding-box;
  padding-top: 50px;
}
.recived {
  margin-left: 7.5%;
  width: 18%;
  min-height: 130px;
  margin-bottom: 50px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  opacity: 1;
}
.outstanding {
  margin-left: 4%;
  width: 18%;
  min-height: 130px;
  margin-bottom: 50px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  opacity: 1;
}
.bar_margin {
  margin-left: 10px;
  margin-right: 10px;
}
.recived_text {
  text-align: start;
  font: normal normal normal 15px Avenir Next;
  letter-spacing: 0px;
  color: #393a3d;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 17px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.amt_text {
  text-align: start;
  font: normal normal 18px Avenir Next;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 10px;
}

.card_table {
  background: #fff;
  border-radius: 20px;
  width: 100%;
  margin-top: 30px;
}
.card_table1 {
  background: #fff;
  border-radius: 20px;
  width: 100%;
  margin-top: 30px;
  overflow: scroll;
  height: 250px;
}
.table_data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px 31px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 3%;
}
.sc-egiyK {
  color: #606060;
  text-transform: uppercase;
  font: normal normal normal 14px Avenir Next;
}
.menu_container {
  display: flex;
  width: 100%;
  justify-content: flex-end  !important;
  /* margin-bottom: -37px; */
}
.tableTitle {
  font-size: 15px;
  font-weight: 600;
  color: black;
  border-bottom: 4px solid #663595 !important;
  margin-right: 22px;
  text-align: initial;
  cursor: pointer;
}
.filter-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c1642;
  opacity: 0.2;
  width: 300px;
  height: 40px;
  border-radius: 5px;
}
.dropdown button {
  /* background-color: #102464;
  color: white;
  margin-left: 8px; */
  display: none;
}
button.download {
  display: none;
}

button.print {
  display: none;
}
.data-table-extensions-action {
  margin-left: 20px !important;
}
.data-table-extensions {
  display: flex;
  justify-content: end;
  margin: 15px 0px;
}
.status_bg {
  min-width: 80px;
  height: 25px;
  border-radius: 3px;
  padding-top: 3%;
  font-weight: 600;
  color: #fff;
}
.slno {
  font: normal normal bold 14px/19px Avenir Next;
  letter-spacing: 0.35px;
  color: #1c1642;
  opacity: 1;
}
.loanid {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0.35px;
  color: #1c1642;
  opacity: 1;
  font-weight: 600;
}
.date {
  font: normal normal medium 14px/19px Avenir Next;
  letter-spacing: 0.35px;
  color: #eb5557;
  text-transform: uppercase;
  opacity: 1;
}
.desc_sub {
  font: normal normal medium 14px/19px Avenir Next;
  letter-spacing: 0.35px;
  color: #1c1642;
  text-transform: uppercase;
  opacity: 1;
}
.amt_table {
  font: normal normal 14px/19px Avenir Next;
  font-weight: 600;
  letter-spacing: 0px;
  color: #102464;
  opacity: 1;
}

.req_stat {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0px;
  font-weight: 600;
  color: #ffe450;
  opacity: 1;
  min-width: 100px;
  height: 25px;
  padding-top: 2%;
  border: 1px solid #ffe450;
  text-align: center;
  text-transform: uppercase;
}
.review_stat {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0px;
  font-weight: 600;
  color: #f5a21e;
  opacity: 1;
  min-width: 100px;
  height: 25px;
  padding-top: 2%;
  border: 1px solid #f5a21e;
  text-align: center;
  text-transform: uppercase;
}
.decline_stat {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0px;
  font-weight: 600;
  color: #f75d24;
  opacity: 1;
  min-width: 100px;
  height: 25px;
  padding-top: 2%;
  border: 1px solid #f75d24;
  text-align: center;
  text-transform: uppercase;
}
.despriced_stat {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0px;
  font-weight: 600;
  color: #2fe6d1;
  opacity: 1;
  min-width: 100px;
  height: 25px;
  padding-top: 2%;
  border: 1px solid #2fe6d1;
  text-align: center;
  text-transform: uppercase;
}
.closed_stat {
  font: normal normal 14px/19px Avenir Next;
  letter-spacing: 0px;
  font-weight: 600;
  color: #26f57d;
  opacity: 1;
  min-width: 100px;
  height: 25px;
  padding-top: 2%;
  border: 1px solid #26f57d;
  text-align: center;
  text-transform: uppercase;
}
.gkJLYo {
  height: 60px;
}
/* .sc-hGPBjI.fuBVgf.rdt_TableBody {
  overflow: scroll;
  height: 110px;
  margin-bottom: 20px;
} */

.loan_request_contain1 {
  width: 1100px;
  background: #fff;
  border-radius: 10px;
  height: 90vh;
  padding: 20px 40px;
}
.display_center {
  display: flex;
  flex-direction: column;
  height: 110px;
  align-items: center;
  justify-content: center;
}
img.upload_logo1 {
  width: 40px;
}
p.text-center1 {
  margin: 5px;
}
.debit_box {
  min-width: 80px;
  height: 25px;
  align-items: center;
  padding-top: 2%;
  font-weight: 600;
  color: #54b701;
  border: 1px solid #54b701;
  text-align: center;
}
.status_box_failure {
  min-width: 80px;
  height: 25px;
  align-items: center;
  padding-top: 2%;
  text-transform: uppercase;
  font-weight: 600;
  color: #e17064;
  border: 1px solid #e17064;
  text-align: center;
}
.status_box_success {
  min-width: 80px;
  height: 25px;
  text-transform: uppercase;
  align-items: center;
  padding-top: 2%;
  font-weight: 600;
  color: #54b701;
  border: 1px solid #54b701;
  text-align: center;
}

.date_popup {
  font: normal normal medium 14px/19px Avenir Next;
  letter-spacing: 0.35px;
  color: #7578d5;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 600;
}

/* .headimg_teamback{
  margin-top: 10px !important;
} */

.nweclasss{
  display: flex;

}

.colNmae{
  color:black;
  font-weight:300;
}

.colNmae2{
  color:black;
  font-weight:300;
  font-size: 12;
}
.invoceheader2022{
  padding: 20px 0 20px 0 ;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-left: 1%;
}
.backarrowButton{
  cursor: pointer;
}

.createInvoicebutton{
  position: absolute;
  top: 159px;
  right: 0;
  width:100%;
  z-index: 10;
  display: flex;
    float: right;
    height: 35px;
    cursor: pointer;
    width: 110px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 2px 2px 7px 0px lightgrey;
    margin-right: 2rem;
    /* display: inline; */
}

.filter-text{
  margin-right:120px;
  float: left !important;
}

.formsubmitbutton:hover{
  background:#663595 !important;
  color:#fff !important;
}
.formsubmitbutton{
  border: 1px solid #663595 !important;
  color:#663595 !important; 
}

