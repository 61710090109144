.invoicewidget {
    background-color: #f7efff;
    padding: 10px;
    padding: 30px 0 30px 0;

}

.createInvoicebutton2 {
    position: absolute;
    top: 315px;
    right: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    float: right;
    height: 35px;
    cursor: pointer;
    width: 110px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 2px 2px 7px 0px lightgrey;
    margin-right: 2rem;
    /* display: inline; */
}

.swal-z-index {
    z-index: 100000;
}