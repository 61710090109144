.p_login {
  display: flex;
  width: 100%;
  min-height: 760px;
  background-color: #fff;
}

.log_col1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.logo_css_purp {
  width: 200px;
  margin-top: 25px;
  margin-left: 25px;
}

img.login_bg {
  width: 86%;
  margin: auto;
}

.log_col2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.login_button {
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-top: 50px;
  cursor: pointer;
}

h2.log_sub {
  color: #313B6E;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
}

h1.log_name {
  color: #313B6E;
  font-size: 34px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px;
}

p.log_p1 {
  color: #fff;
  margin-left: 139px;
  margin-top: -36px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  margin-left: 58px;

}

img.login_but {
  width: 100%;
  height: 38px;
}

.p_login {
  display: flex;
  width: 100%;
  min-height: 760px;
  background-color: #fff;
}

.log_col1 {
  width: 60%;
  display: flex;
  align-items: flex-start;

  /* align-content: center; */
  /* justify-content: center; */
  /* align-items: center; */
}

.log_col2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.log_col22 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.login_button {
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-top: 50px;
  cursor: pointer;
}

h2.log_sub {
  color: #313b6e;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
}

h1.log_name {
  color: #663495;
  font-size: 34px;
  font-weight: 700;
  /* text-transform: uppercase; */
  align-items: center;
  text-align: center;
  margin: 0px;
  margin-bottom: 50px;
}

p.log_p1 {
  color: #fff;
  margin-left: 139px;
  margin-top: -36px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  margin-left: 58px;
}

img.login_but {
  width: 100%;
  height: 38px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 285px;
}

/* .btn_login {
  border: none;
  border-radius: 5px;
  background-color: #141e2d;
  color: #e6b95e;
  padding: 10px 10px;
  font-size: 12px;
} */
.flex-right {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.btn_login {
  border: none;
  border-radius: 5px;
  background-color: #141e2d;
  color: #e6b95e;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}

button.btn_login_off {
  border: none;
  border-radius: 5px;
  background-color: #dfdfdf;
  color: #000;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 800;
  pointer-events: none;
}

p.log_p2 {
  font-size: 14px;
  margin: 0;
  margin-bottom: 15px;
}

p.log_p21 {
  width: '20px'
}

button.button_verify {
  margin-top: 25px;
  background: #663595;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 800;
  cursor: pointer;
}

.timer_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

p.timer_text {
  margin: 0;
  color: #673695;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
}

img.timer_logo {
  width: 20px;
  height: 20px;
}

p.otp_query {
  font-size: 12px;
}

span.otp_resend {
  color: #F99746;
  font-weight: 600;
  margin-left: 6px;
  cursor: pointer;
}

span.otp_resend1 {
  font-weight: 600;
  margin-left: 6px;
  color: #a19c9c;
  pointer-events: none;
}

.logo_css_side {
  width: 160px;
}