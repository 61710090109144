.icon_css_total {
    align-items: center;
    display: flex;
    margin-right: 9%;

}
p.fw-small {
    font-size: 14px;
}
.card_css_height{
    max-height: 150px;
    min-height: 150px;
}
.left_card_css_col{
    display: flex;
    flex-direction: column;
}
.card-body {
    height: 117px;
}

