.name_css {
    margin: 10px 0;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 10px;
}

.number_css {
    margin: 5px 0;
    text-transform: uppercase;
}

.unit_css {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
}

.model_contain {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container {
    background-color: #F7EEFF;
    border-radius: 25px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.35);
    padding: 90px 0 60px;
    position: relative;
    width: 350px;
    max-width: 100%;
    text-align: center;
    margin: 20px 0;
    overflow: hidden;
    border-bottom: 10px solid #673695;
}

.card-container .pro {
    color: #F7EEFF;
    background-color: #673695;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    padding: 3px 7px;
    position: absolute;
    top: 30px;
    left: 30px;
    text-transform: uppercase;
}

.img_css_profile {
    border: 1px solid #673695;
    border-radius: 50%;
    padding: 7px;
    height: 150px;
    width: 150px;
}

.non_img_css_profile {
    border: 1px solid #673695;
    border-radius: 50%;
    padding: 7px;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    text-transform: uppercase;
}

.non_img_css_profile_outside {
    display: flex;
    justify-content: center;
}

.email_css {
    background-color: #673695;
    color: white;
    padding: 6px 15px;
    margin-bottom: 36px;
    border-radius: 5px;
}

.edit_profile_css {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 61%;
    z-index: 10;
    top: 49%;
    background-color: #E2DEFE !important;
    color: #673695 !important;
}

.label_css {
    margin-bottom: 0px !important;
}