.nav_head {
  background-color: #313b6e;
  min-height: 60px;
}
.icon_placer {
  padding-top: 15px;
}
.threeline {
  padding-left: 10px;
}
.icons_end {
  background: #f8f7ff 0% 0% no-repeat padding-box;
  height: 37px;
  width: 37px;
  border-radius: 5px;
  opacity: 1;
  align-items: center;
  margin-top: -6px;
  padding-top: 5px;
  padding-left: 1px;
  margin-right: 20px;
}
.profile{
  background: #f8f7ff 0% 0% no-repeat padding-box;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  opacity: 1;
  align-items: center;
  margin-top: -6px;
  padding-top: 5px;
  padding-left: 1px;
  margin-right: 20px;
  margin-left: 5px;
  text-transform: uppercase;
}
.modal-dialog,
.modal-content {
    width: 80vw;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.taskmoimagelog{
  width:40px
}