.upload_css {
    border: 1px solid grey;
    display: flex;
    height: 30px;
    align-items: center;
    border-radius: 5px;
    color: #663595;
    padding: 0px 10px;
    height: 55px;
    flex-direction: row-reverse;
}