.icon_css_total {
    align-items: center;
    display: flex;
    margin-right: 9%;

}
p.fw-small {
    font-size: 14px;
}
.card_css_height{
    max-height: 150px;
    min-height: 150px;
}
.left_card_css_col{
    display: flex;
    flex-direction: column;
}
.card_body_widget{
    height:80px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.icon_css_total3{
    
    font-size: 25px;
    color: #663595 !important
}

.icon_css_total1{
    
    font-size: 25px;
    color: #663595 !important
}

.icon_css_total2{
   
    font-size: 25px;
    color: #663595 !important
}

