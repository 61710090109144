.inv_contain {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    height: 100vh;
    padding: 20px 40px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .tax_head{
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 1px;
  }
  .logo_left_css{
    width: 200px;
    height: 70px;
  }
  .date_data_css{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .right_tax_css{
    color: #555555;
    font-weight: 700;
  }
  .head_bill_css{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: start;
  }
  .legal_name_css{
    color: #777777;
    font-size: 14px;
    display: flex;
    justify-content: flex;

  }
  .legal_name_css1{
    color: #777777;
    font-size: 14px;
    width: 40%;
    display: flex;
    justify-content: start;
    overflow-wrap: break-word;
    text-align: left;
  }
  .widt_css{
  width: 40%;
  white-space: normal;
  display:flex;
  justify-content: flex-start;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: initial;
  
  }
  .invdEtaModal{
    display: flex;
    justify-content: center;
    overflow-x:scroll;
  }